<template>
  <div class="book-detail">
    <p class="title">企业商业机密保护的最佳实践</p>
    <div>
      <p class="ptittle">书本介绍</p>
      <span
        >本书是专门为广大中小型介绍如何快速、有效地建立一系列“小而精”的管理手段，以保护公司核心商业机密。这是我司长期为多家企业提供信息安全咨询的实践总结。</span
      >
    </div>
    <br />
    <div>
      <p class="ptittle">内容介绍</p>
      <span
        >商业信息和技术信息是企业重要的无形资产，对企业的生存和发展有着重要的影响。但是随着市场竞争日趋激烈，对商业机密的争夺也变得无所不用其极，企业对商业机密保护的重视程度与日俱增。
        但对于一些中小企业来说，建立一套完备的信息安全管理体系耗时长、投入大、人员能力跟不仧，他们只需要一套“小而精"的管理手段，快速、有效地来保守公司最核心的商业机密。
      </span>
      <br />
      <span>
        本书正是为有这种现实需要的企业而编写的，本书是我公司长期为多家企业建立信息安全管理体系或单纯的商业机密保护体系的实践总结。我们在建立体系的过程中，应用了“过程方法”这一国际公认的最佳实效方法，首家独创把过程方法与ISO/IEC27001的最佳实践结合起来，根据企业的实际情况和需求，“量力而为”，能快速地建立最有效的保护方法。我们把这些经验和实例汇编在本书中提供借鉴，并启发读者如何去运用过程方法ISO/IEC27001标准，使建立出来的信息安全管理体系更有效、更符合企业自身的特点，而非生搬硬套其他公司的经验和方法。
      </span>
      <br />
      <br />
      <div class="divp">
        <span>本书分为10个章节：</span>
        <p><span>第一章</span><span>商业机密保护的过程方法</span></p>
        <p><span>第二章</span><span>怎样发现泄密风险</span></p>
        <p><span>第三章</span><span>商业机密的保护制度</span></p>
        <p><span>第四章</span><span>人力资源防泄密</span></p>
        <p><span>第五章</span><span>技术辅助</span></p>
        <p><span>第六章</span><span>应急处理和法律运用</span></p>
        <p><span>第七章</span><span>自我检查和完善</span></p>
      </div>
      <br />
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style lang="less" scoped>
@import "../../../assets/style/BookStyle.css";
</style>